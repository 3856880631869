@import '../../../assets/scss/colors.scss';

.header {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  background-color: #fe4353;
  border-radius: 20px;
  font-family: 'Open Sans Condensed';
  font-weight: bold;

  .logo-container {
    height: 100%;
    width: 150px;
    padding: 10px;

    img {
      width: 150px;
      height: 50px;
    }
  }

  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:last-child {
      padding-right: 15px;
    }

    .option {
      padding: 10px;
      cursor: pointer;
      color: $sub-background-color;
      text-transform: uppercase;

      &.active {
        display: none;
      }

      img {
        border-radius: 50%;
        border: 1px solid $pink-color;
        height: 40px;
        width: 40px;
      }
    }
  }
}
