@import '../../assets/scss/colors.scss';
@import '../navigation/header/header.styles.scss';

.home-page {
    h1 {
        font-weight: bold;
        font-size: 36pt;
        margin-bottom: 10px;
        color: #fff;

        b {
            color: black;
        }
    }

    h2 {
        font-weight: bold;
        font-size: 24pt;
        color: #fff;
        margin-bottom: 10px;
        margin-top: 10px;

        b {
            color: black;
        }
    }

    h3 {
        color: $description-color;
        font-weight: 600;
        font-size: 16pt;
        margin-top: 10px;

        b {
            color: #fff;
        }
        strong {
            background: linear-gradient(45deg, $logo-color 30%, green 90%);
            background-clip: text;
            color: transparent;
        }
    }

    h4 {
        font-size: 14pt;
    }

    strong {
        background: linear-gradient(45deg, $logo-color 30%, green 90%);
        background-clip: text;
        color: transparent;
    }

    .header {
        border-radius: 0px;
        margin-bottom: 0px;
        background: linear-gradient(45deg, $logo-color 30%, green 90%);

        img {
            width: auto;
        }
    }

    .footer {
        border-top: none;
        color: $description-color;

        a {
            color: $description-color;
        }
    }

    .switch-direction {
        direction: rtl;
    }

    .decorated {
        background-image: url("../../assets/images/blurryBackground.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .custom-button {
        background: linear-gradient(45deg, $logo-color 30%, green 90%);
        color: $sub-background-color;
        border: 1px solid #3FAA21;

        &:hover {
            background: linear-gradient(45deg, green 30%, $logo-color 90%);
            font-weight: bolder;
        }
    }

    .banner {
        position: relative;
        width: 100%;
        height: 550px;
        background-color: black;

        .custom-button {
            margin: auto;
        }

        .banner-image {
            width: 100%;
            height: 550px;
            object-fit: cover;
            opacity: 0.7;
        }

        .banner-video {
            width: 100%;
            height: 550px;
            object-fit: cover;
            opacity: 0.7;
        }

        .banner-text {
            position: absolute;
            bottom: 0px;
            left: 0px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50%;
            text-align: center;

            h1 {
                padding: 0% 20%;
                margin: 0px 0px;
            }
        }
    }

    .container {
        padding: 2% 10%;
        text-align: center;
        background-color: #181A1B;
        h1 {
            font-size: 42pt;
        }

        h4 {
            text-transform: uppercase;
            color: $logo-color;
            font-weight: bold;
            margin: 0px;
            padding: 0px;
        }

        .container-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin-top: 20px;

            .grid-item {
                text-align: left;
                gap: 20px;

                img {
                    max-width: 300px;
                    height: 400px;
                    object-fit: cover;
                    border-radius: 20px;
                }
            }
        }

        .container-grid-duo {
            display: grid;
            grid-template-columns: 2fr 1fr;
            margin-top: 20px;

            .left {
                padding: 0px 40px;
                text-align: left;
                margin: auto;
            }

            ul {
                list-style: none;
                padding: 0px 20px;
                direction: ltr;
                color: $sub-color;
            }

            ul li:before {
                content: '✓';
                color: $logo-color;
            }

            li {
                margin-top: 5px;
            }

            // img {
            //     height: 600px;
            // }
        }

        .container-grid-duo-reverse {
            display: grid;
            grid-template-columns: 1fr 3fr;
            margin-top: 20px;

            #merchSpread {
              width: 100%;
            }

            .selected {
                font-family: Georgia, 'Times New Roman', Times, serif;
                font-variant: small-caps;
                margin: auto;
                h1 {
                    font-size: xx-large;
                }

                h1, h3 {
                    color: $description-color;

                    a, a:visited {
                        color: $description-color;
                    }
                }
            }

            .merch {
               margin: auto;

               text-align: left;
                h1 {
                    color: #202032;
                    font-size: 24pt;
                    line-height: 47px;
                }

            }
        }
    }

    .container-gray {
        background-color: #3B4043;
    }

    .container-light-gray {
      background-color: #f6f6f8;
    }

    #mc_embed_signup {
        background: none;

        form {
            padding: 0px;

            .indicates-required {
                color: $sub-background-color;
            }

            label {
                color: $sub-background-color;
            }

            input {
                border: none;
                border-bottom: 1px solid $sub-background-color;
                background-color: transparent;
                color: $sub-background-color;
            }

            input[type=submit] {
                text-transform: uppercase;
                font-family: 'Open Sans Condensed';
                font-weight: bolder;
                min-width: 165px;
                height: 50px;
                letter-spacing: 0.5px;
                width: 20%;
                border-radius: 20px;
                outline: none;
                display: inline;
                // background-color: $pink-color;
                background: linear-gradient(45deg, $logo-color 30%, green 90%);
                border: 1px solid $logo-color;
                color: $sub-background-color;

                &:hover {
                background: linear-gradient(45deg, green 30%, $logo-color 90%);
                  color: $sub-background-color;
                  font-weight: bold;
                }
            }
        }
    }

    #expressYourself {
        height: 500px;
    }

    #fiLogo {
        height: 200px;
    }
}

@media only screen and (max-width: 1024px) {
    .home-page {
        h1 {
            font-weight: bold;
            font-size: 24pt;
        }

        .header {
            .options {
                display: none;
            }
        }

        .banner {
            .banner-text {
                height: 50%;
            }
        }

        .container {
            padding: 2% 5%;
            .container-grid {
                grid-template-columns: 1fr 1fr 1fr;

                .grid-item {
                    text-align: center;
                    h3 {
                        padding: 0px 20%;
                        margin-bottom: 20px;
                    }
                    img {
                        max-width: 300px;
                        height: 300px;
                    }
                }
            }

            .container-grid-duo {
                display: grid;
                grid-template-columns: 2fr 1fr;
                margin-top: 20px;
                .grid-item {

                    h3 {
                        padding: 0px 10%;
                        margin-bottom: 20px;
                    }
                }
            }
        }

        #expressYourself {
            height: 500px;
        }
    }
}

// mobile

@media only screen and (max-width: 800px) {
    .home-page {
        h1 {
            font-weight: bold;
            font-size: 24pt;
        }

        .header {
            .options {
                display: none;
            }
        }

        .banner {
            .banner-text {
                height: 70%;
            }
        }
        .custom-button {
            margin: auto;
        }

        .container {
            .container-grid {
                grid-template-columns: 1fr;

                .grid-item {
                    text-align: center;
                    h3 {
                        padding: 0%;
                        margin-bottom: 20px;
                    }
                    img {
                        max-width: 250px;
                        height: 250px;
                    }
                }
            }

            .container-grid-duo {
                display: grid;
                grid-template-columns: 1fr;
                margin-top: 20px;

                h2 {
                    padding: 1% 3%;
                    text-align: center;
                }

                h3 {
                    padding: 1% 3%;
                    margin-bottom: 20px;
                    text-align: center;
                }

                h4 {
                    text-align: center;
                }

                img {
                    // height: 400px;
                    margin: auto;
                }

                ul {
                    text-align: center;
                }
            }

            .container-grid-duo-reverse {
                display: grid;
                grid-template-columns: 1fr;
                margin-top: 20px;

                img {
                    margin: auto;
                }

                .small {
                  width: 400px;
                }

                .selected {
                    font-family: Georgia, 'Times New Roman', Times, serif;
                    font-variant: small-caps;

                    h1 {
                        font-size: xx-large;
                    }

                    h1, h3 {
                        color: $description-color;
                    }
                }

                .merch {
                  text-align: left;
                    h1 {
                        color: #202032;
                        font-size: xx-large;
                        line-height: 67px;
                    }
                }
            }
        }

        #expressYourself {
            height: 220px;
        }

        #enterprise {
            width: 300px;
            height: 300px;
        }

    }
}
