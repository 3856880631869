html {
  height: 100%;
}

body {
  font-family: "SF Pro Display", 'Noto Sans JP', 'Open Sans Condensed', "SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif, 'Open Sans Condensed';
  background-color: #f4f5f7;
  height: 100%;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}

.modal {
  /* position: absolute;
  inset: 40px; */
  display: inline-block;
  border: 1px solid rgb(204, 204, 204);
  background: #ffffff;
  overflow: auto;
  border-radius: 20px;
  outline: none;
  padding: 20px;
  min-width: 20%;
  max-width: 50%;
  min-height: 20%;
  max-height: 80%;
}

.modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}