@import '../../../assets/scss/colors.scss';

.custom-button {
  min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: #4285f4;
  color: white;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 10px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  box-shadow: none;
  outline: none;

  &:hover {
    background-color: #357ae8;
    border: none;
  }

  &.small {
    height: 30px;
    line-height: 30px;
    min-width: 20px;
  }

  &.google-sign-in {
    background-color: #e57368;
    color: white;

    &:hover {
      background-color: #DB4437;
      border: none;
    }
  }

  &.twitter-sign-in {
    background-color: #08a0e9;
    color: white;

    &:hover {
      background-color: #0084B4;
      border: none;
    }
  }

  &.anonymous-sign-in {
    background-color: black;
    color: white;

    &:hover {
      background-color: white;
      color: black;
      border: 1px solid black;
    }
  }

  &.inverted {
    background-color: white;
    color: black;
    border: 1px solid black;

    &:hover {
      background-color: black;
      color: white;
    }
  } 
}

.custom-vent-button {
  width: 20%;
  border-radius: 20px;
  outline: none;
  display: inline;
  background-color: $pink-color;
  border: 1px solid $logo-color; 
  color: $logo-color;

  &:hover {
    background-color: $logo-color;
    color: $sub-background-color;
    font-weight: bold;
  }
}

.custom-success-button {
  width: 20%;
  border-radius: 20px;
  outline: none;
  display: inline;
  background-color: #068A0A;
  border: 1px solid #07bc0c; 
  color: white;

  &:hover {
    background-color: #07bc0c;
    color: $sub-background-color;
    font-weight: bold;
  }
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.display {
  display: block;
}

.hide {
  display: none;
}