@import '../../assets/scss/colors.scss'; 
@import '../navigation/header/header.styles.scss';

.terms-page {
    color: #fff;
    h1 {
        font-weight: bold;
        font-size: 36pt;
        margin-bottom: 10px;
        color: #fff;

        b {
            color: black; 
        }
    }

    h2 {
        font-weight: bold;
        font-size: 24pt;
        color: #fff;
        margin-bottom: 10px;
        margin-top: 10px;

        b {
            color: black; 
        }
    }

    h3 {
        color: $description-color;
        font-weight: 600;
        font-size: 16pt;
        margin-top: 10px;

        b {
            color: #fff;
        }
        strong {
            background: linear-gradient(45deg, $logo-color 30%, green 90%);
            background-clip: text;
            color: transparent;
        }
    }

    h4 {
        font-size: 14pt;
        text-transform: uppercase;
        color: $logo-color; 
        font-weight: bold;
        margin: 0px;
        padding: 0px;
    }


    .header {
        border-radius: 0px;
        margin-bottom: 0px;
        background: linear-gradient(45deg, $logo-color 30%, green 90%);

        img {
            width: auto;
        }
    }

    .banner {
        position: relative;
        width: 100%;
        height: 250px;
        background-color: black;

        .custom-button {
            margin: auto;
            background: linear-gradient(45deg, $logo-color 30%, green 90%);
            color: $sub-background-color;
            border: 1px solid #3FAA21;

            &:hover {
                background: linear-gradient(45deg, green 30%, $logo-color 90%);
                font-weight: bolder;
            }
        }

        .banner-image {
            width: 100%;
            height: 250px;
            object-fit: cover;
            opacity: 0.7;
        }
        
        .banner-video {
            width: 100%;
            height: 550px;
            object-fit: cover;
            opacity: 0.7;
        }
    
        .banner-text {
            position: absolute;
            bottom: 0px;
            left: 0px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 80%;
            text-align: center;
    
            h1 {
                padding: 0% 20%;
                margin: 20px 0px;
            }
        }
    }

    .container {
        padding: 2% 10%;
        text-align: center;
        background-color: #181A1B;
        h1 {
            font-size: 42pt;
        }

        h4 {
            text-transform: uppercase;
            color: $logo-color; 
            font-weight: bold;
            margin: 0px;
            padding: 0px;
        }

        .container-grid {
            display: grid; 
            grid-template-columns: 1fr 1fr;   
            margin-top: 20px;

            .grid-item {
                text-align: left;
                gap: 20px;

                img {
                    max-width: 600px;
                    height: 200px;
                    border-radius: 20px;
                }
            }
        }

        .container-grid-duo {
            display: grid; 
            grid-template-columns: 2fr 1fr;   
            margin-top: 20px;

            .left {
                padding: 0px 40px;
                text-align: left;
                margin: auto;
            }

            ul {
                list-style: none;
                padding: 0px 20px;
                direction: ltr;
                color: $sub-color;
            }
              
            ul li:before {
                content: '✓';
                color: $logo-color;
            }

            li {
                margin-top: 5px;
            }

            // img {
            //     height: 600px;
            // }
        }

        .container-grid-duo-reverse {
            display: grid; 
            grid-template-columns: 1fr 3fr;   
            margin-top: 20px;

            .selected {
                font-family: Georgia, 'Times New Roman', Times, serif;
                font-variant: small-caps;
                margin: auto;
                h1 {
                    font-size: xx-large;
                }

                h1, h3 {
                    color: $description-color;

                    a, a:visited {
                        color: $description-color;
                    }
                }
            }
        }
    }

    .container-gray {
        background-color: #3B4043;
    }

    .footer {
        border-top: none;
        color: $description-color;

        a {
            color: $description-color;
        }
    }
}

// mobile

@media only screen and (max-width: 800px) {
    .terms-page {
        .banner {
            .banner-text {
                h1 {
                    font-size: 32pt;
                }
            }
        }        
        
        .container {
            .container-grid {
                grid-template-columns: 1fr;  
                
                .grid-item {
                    text-align: center;
                }
            }
        }
    }
}